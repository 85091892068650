import React, { useState, useEffect } from 'react';
import './Hero.css'
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Typewriter from 'typewriter-effect';

// AOS.init();
const Hero = () => {

    const [isTypeWritterPlay, setIsTypeWritterPlay] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setIsTypeWritterPlay(true);
        }, 1500);
    }, []);

    return (
        <>
            <div className='hero-area grid-cols-1 grid lg:grid-cols-1 items-center mx-auto lg:px-24 px-10 py-28 lg:py-0 h-screen'>
                <div className="hero-text text-center">
                    <h3 className='lg:text-4xl text-3xl mb-6 lg:mb-10 lg:mt-32'>Hi I'm</h3>
                    <h3 className='lg:text-7xl text-4xl mb-6 lg:mb-10 capitalize'>Monib Bormon.</h3>
                    <h2 className='lg:text-4xl text-2xl mb-8 capitalize text-style'>I'm a
                        <Typewriter
                            options={{
                                strings: [
                                    'MERN Stack Developer',
                                    'Programmer',
                                    'Web Application Developer',
                                ],
                                autoStart: isTypeWritterPlay,
                                deleteSpeed: 50,
                                delay: 70,
                                loop: true,
                                cursor: '',
                            }}
                        /></h2>
                    <Link to={{ pathname: 'https://drive.google.com/uc?export=download&id=1vqbFGWJBhCNM2aZWzyctmKxqeB25trsy' }} target='_blank'>
                        <button className='border-2 border-blue-500 px-5 py-2 mb-10 inline-block rounded-lg font-semibold capitalize'>Download Resume</button>
                    </Link>
                    <div className="follow">
                        <ul>
                            <li><a target='_blank' rel="noreferrer" href="https://github.com/monibbormon"><i class="fab fa-github-square"></i></a></li>
                            <li><a target='_blank' rel="noreferrer" href="https://www.linkedin.com/in/monib-bormon-963b81176/"><i class="fab fa-linkedin"></i></a></li>
                            <li><a target='_blank' rel="noreferrer" href="https://stackoverflow.com/users/16667746/monib-bormon?tab=profile"><i class="fab fa-stack-overflow"></i></a></li>
                            <li><a target='_blank' rel="noreferrer" href="https://www.facebook.com/MonibBormon/"><i class="fab fa-facebook-square"></i></a></li>
                            <li><a target='_blank' rel="noreferrer" href="https://medium.com/@monibbormon14"><i class="fab fa-medium"></i></a></li>
                        </ul>
                    </div>
                </div>
                {/* <div className="hero-image hidden md:block">
                <img className='h-screen' src={hero} alt="" />
            </div> */}
            </div>
        </>
    );
};

export default Hero;