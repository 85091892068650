import React from 'react';
import Particles from "react-tsparticles";
import { particle } from './particle.config';

const Particle = () => {
    return (
        <div className='z-0'>
            <Particles params={particle} />
        </div>
    );
};

export default Particle;