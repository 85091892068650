import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './App.css';
import About from './components/About/About';
import ContactPage from './components/ContactPage/ContactPage';
import Header from './components/Header/Header';
import Home from './components/HomePage/Home/Home';
import ProjectDetails from './components/Projects/ProjectDetails/ProjectDetails';
import Projects from './components/Projects/Projects';
import { Helmet } from 'react-helmet'

function App() {
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Monib Bormon | MERN Stack Web Developer | Web Application Developer</title>
        <meta name="description" content="Monib Bormon is a MERN stack web developer who builds interactive websites for client." />
        <meta name='keywords' content='Monib Bormon, Web Developer, Mern Stack Developer, Front end developer' />
        <meta property="og:title" content="Monib Bormon | MERN Stack Developer | Web Application Developer" />
        <meta property="og:description"
          content="Monib Bormon is a MERN stack web developer who builds interactive websites for client." />
        <meta property="og:site_name" content="Monib Bormon | MERN Stack Developer | Web Application Developer" />
        <meta property="og:url" content="https://monib-bormon.netlify.app/" />
        <meta name="author" content="Monib Bormon"></meta>
      </Helmet>
      <BrowserRouter>
        <Header></Header>
        <Switch>
          <Route exact path='/'>
            <Home></Home>
          </Route>
          <Route path='/home'>
            <Home></Home>
          </Route>
          <Route path='/about'>
            <About></About>
          </Route>
          <Route path='/projects'>
            <Projects></Projects>
          </Route>
          <Route path='/project-details/:title'>
            <ProjectDetails />
          </Route>
          <Route path='/contact'>
            <ContactPage />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
