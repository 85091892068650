import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2'
import ContactInfo from '../ContactInfo/ContactInfo';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import cogoToast from 'cogo-toast';

AOS.init();
const ContactPage = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);

    const [messageDone, setMessageDone] = useState(false);

    const data = {
        name,
        email,
        message
    }

    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        if (data.name !== "" && data.email !== "" && data.message !== "") {
            setMessageDone(true)
            setName("");
            setEmail("");
            setMessage("")
            cogoToast.success("Message Sent Successfully!", {
                position: "bottom-center"
            })
        }

        // emailjs.sendForm('gmail', 'template_76bl2aj', form.current, 'user_4m4eUwfTfzsg8wmNLDZi7')
        //     .then((result) => {
        //         Swal.fire({
        //             position: 'top-center',
        //             icon: 'success',
        //             title: 'Your Messege Sent Successfully.',
        //             showConfirmButton: false,
        //             timer: 2000
        //         })
        //         e.target.reset()
        //     }, (error) => {
        //         console.log(error.text);
        //     });

    };
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Monib Bormon | Contact With me </title>
                <meta name="description" content="Monib Bormon is a MERN stack web developer who builds interactive websites for client." />
                <meta name='keywords' content='Monib Bormon, Web Developer, Mern Stack Developer, Front end developer' />
            </Helmet>

            <div className='pt-24 bg-gray-50'>
                <div className="container mx-auto px-5 lg:px-0 py-10">
                    <h2 className='lg:text-5xl text-2xl text-center mb-10 font-semibold'>Feel Free to Contact Me</h2>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 items-center">
                        <div data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="200">
                            <ContactInfo />
                        </div>
                        <div data-aos="zoom-in-up" data-aos-duration="2000" data-aos-delay="200" className="contact-form">

                            <form ref={form} onSubmit={sendEmail}>
                                <input onChange={(e) => setName(e.target.value)} className={`w-full border-2 mb-3 px-3 py-3 rounded-md ${error ? "border-red-400" : "border-blue-400"} focus:outline-none focus:ring-1 focus:ring-blue-500`} required='true' value={name} type="text" placeholder='Your Full Name' name='name' />

                                <input onChange={(e) => setEmail(e.target.value)} className='w-full border-2 mb-3 px-3 py-3 rounded-md border-blue-400 focus:outline-none focus:ring-1 focus:ring-blue-500' required='true' type="email" placeholder='Your Email' value={email} name='email' />

                                <textarea onChange={(e) => setMessage(e.target.value)} className='w-full border-2 mb-3 px-3 py-3 rounded-md border-blue-400 focus:outline-none focus:ring-1 focus:ring-blue-500 h-32' required='true' name="message" id="message" cols="30" value={message} rows="10" placeholder='type your message...'></textarea>

                                <input className='bg-blue-500 text-white font-semibold px-5 py-2 cursor-pointer' type="submit" value='Send Message' />

                                {/* {messageDone ? <div>
                                    <h3 className='pt-2 text-red-400'>Message Send Successfully!</h3>
                                </div> : <div></div>} */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactPage;