import React from 'react';
import Hero from '../../Hero/Hero';
import Particle from '../../Particle/Particle';


const Home = () => {

    return (
        <div>
            <Particle />
            <Hero />
        </div>
    );
};

export default Home;