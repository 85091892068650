import React from 'react';
import aboutImg from '../../images/about-img-monib.jpg'
import Skilled from '../Skilled/Skilled';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import './About.css';

AOS.init();

const About = () => {
    const name = '<Monib />'
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Monib Bormon | About Me</title>
                <meta name="description" content="Monib Bormon is a MERN stack web developer who builds interactive websites for client." />
                <meta name='keywords' content='Monib Bormon, Web Developer, Mern Stack Developer, Front end developer' />
            </Helmet>
            <div className='bg-gray-50'>
                <div className="md:container md:mx-auto px-5 lg:px-0 pb-10 pt-32 z-0">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                        <div data-aos="zoom-in-up" data-aos-duration="2000" data-aos-delay="200" className="about-img bg-white lg:transform transition-all duration-300 hover:-rotate-6 p-3 shadow-lg rounded-lg">
                            <div className='img-gray'>
                                <img className='rounded-lg' src={aboutImg} alt="" />
                            </div>
                        </div>
                        <div data-aos="zoom-in-right" data-aos-duration="2000" data-aos-delay="200" className="about-text col-span-3 py-12 lg:py-5 lg:ml-16 lg:mr-36">
                            <h4 className='text-xl font-medium mb-5'>Hello there..</h4>
                            <p className='text-xl leading-loose'>This is <span className='text-blue-500 text-4xl font-bold px-1'>{name}</span> I build things for the web. I have been working with web development since 2018. I have done many projects and also done professional courses in Web Development. I am an expert Web Developer who can solve any complicated problems. I am very comfortable with the web technologies and have the ability to overcome any difficult situation happend with my work. </p>
                        </div>
                    </div>
                    <Skilled></Skilled>
                </div>
            </div>
        </>
    );
};

export default About;