import React, { useState, useEffect } from 'react';
import './Header.css';
import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { Link, NavLink } from 'react-router-dom';

const Header = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [prevScrollPos]);
    return (
        <div>
            <div className={`shadow-xl nav-style fixed w-full h-auto z-10 ${visible ? 'fade-in' : 'fade-out'
                }`}>
                <>
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex justify-between gap-4 items-center py-5">
                            <div className="flex-shrink-0">
                                <Link to='/'>
                                    <h2 style={{ fontVariant: 'small-caps' }} className='text-3xl font-semibold text-white'> {`<Monib/>`}</h2>
                                </Link>
                            </div>
                            <div className="hidden md:block text-right">
                                <div className="ml-10 space-x-6">
                                    <NavLink exact activeClassName='active' className='text-white font-semibold text-xl lg:text-2xl lowercase' to='/'>{`.It's Monib()`}</NavLink>
                                    <NavLink activeClassName='active' className='text-white font-semibold text-xl lg:text-2xl lowercase' to='/projects'>{`.Projects()`}</NavLink>
                                    <NavLink activeClassName='active' className='text-white font-semibold text-xl lg:text-2xl lowercase' to='/about'>{`.About()`}</NavLink>
                                    <NavLink activeClassName='active' className='text-white font-semibold text-xl lg:text-2xl lowercase' to='/contact'>{`.Contact()`}</NavLink>
                                </div>
                            </div>
                            <div className="-mr-2 p-2 flex justify-end md:hidden">
                                {/* Mobile menu button */}
                                <div className="inline-flex items-center justify-center rounded-md text-white hover:text-white focus:outline-none cursor-pointer">
                                    <span className="sr-only">Open main menu</span>
                                    {isMobileMenuOpen ? (
                                        <XIcon onClick={() => setIsMobileMenuOpen(false)} className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <MenuIcon onClick={() => setIsMobileMenuOpen(true)} className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* mobile menu  */}
                    {isMobileMenuOpen &&
                        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 md:hidden transition-all duration-300">
                            <li className='list-none' onClick={() => setIsMobileMenuOpen(false)}>
                                <NavLink exact activeClassName='active' className='text-white border-transparent font-semibold text-xl inline-block' to='/'>{`.It's Monib()`}</NavLink>
                            </li>
                            <li className='list-none' onClick={() => setIsMobileMenuOpen(false)}><NavLink activeClassName='active' className='text-white border-transparent font-semibold text-xl inline-block' to='/projects'>{`.Projects()`}</NavLink></li>
                            <li className='list-none' onClick={() => setIsMobileMenuOpen(false)}><NavLink activeClassName='active' className='text-white border-transparent font-semibold text-xl inline-block' to='/about'>{`.About()`}</NavLink></li>
                            <li className='list-none' onClick={() => setIsMobileMenuOpen(false)}><NavLink activeClassName='active' className='text-white border-transparent font-semibold text-xl inline-block' to='/contact'>{`.Contact()`}</NavLink></li>
                        </div>}
                </>
            </div>
        </div >
    );
};

export default Header;