import React from 'react';
import './Skilled.css'

const Skilled = () => {
    return (
        <div>
            <div className="md:container md:mx-auto py-5 lg:py-20 mb-20 lg:mb-0">
                <div>
                    <h3 className='font-semibold text-4xl mb-8 text-gray-700'>I am skilled at</h3>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-6">
                    <div className="single-skill-box flex items-center shadow-lg justify-center py-5 rounded-lg border-b-4 border-transparent hover:border-blue-500 transition-all duration-300 ease-in-out mb-5">
                        <img src="./images/skills/react.png" alt="react" className='w-10 rounded mr-3' />
                        <div className="skill-meta">
                            <h2 className='text-2xl text-blue-500 font-semibold'>React Js</h2>
                        </div>
                    </div>
                    <div className="single-skill-box flex items-center shadow-lg justify-center py-5 rounded-lg border-b-4 border-transparent hover:border-blue-500 transition-all duration-300 ease-in-out mb-5">
                        <img src="./images/skills/nextjs.png" alt="react" className='w-10 rounded mr-3' />
                        <div className="skill-meta">
                            <h2 className='text-2xl text-blue-500 font-semibold'>Next Js</h2>
                        </div>
                    </div>
                    <div className="single-skill-box flex items-center shadow-lg justify-center py-5 rounded-lg border-b-4 border-transparent hover:border-blue-500 transition-all duration-300 ease-in-out mb-5">
                        <img src="./images/skills/typescript.png" alt="react" className='w-10 rounded mr-3' />
                        <div className="skill-meta">
                            <h2 className='text-2xl text-blue-500 font-semibold'>Typescript</h2>
                        </div>
                    </div>
                    <div className="single-skill-box flex items-center shadow-lg justify-center py-5 rounded-lg border-b-4 border-transparent hover:border-blue-500 transition-all duration-300 ease-in-out mb-5">
                        <img src="./images/skills/redux.jpg" alt="react" className='w-10 rounded mr-3' />
                        <div className="skill-meta">
                            <h2 className='text-2xl text-blue-500 font-semibold'>Redux</h2>
                        </div>
                    </div>
                    <div className="single-skill-box flex items-center shadow-lg justify-center py-5 rounded-lg border-b-4 border-transparent hover:border-blue-500 transition-all duration-300 ease-in-out mb-5">
                        <img src="./images/skills/html51.png" alt="react" className='w-10 rounded mr-3' />
                        <div className="skill-meta">
                            <h2 className='text-2xl text-blue-500 font-semibold'>Html5</h2>
                        </div>
                    </div>
                    <div className="single-skill-box flex items-center shadow-lg justify-center py-5 rounded-lg border-b-4 border-transparent hover:border-blue-500 transition-all duration-300 ease-in-out mb-5">
                        <img src="./images/skills/css3.png" alt="react" className='w-10 rounded mr-3' />
                        <div className="skill-meta">
                            <h2 className='text-2xl text-blue-500 font-semibold'>Css3</h2>
                        </div>
                    </div>
                    <div className="single-skill-box flex items-center shadow-lg justify-center py-5 rounded-lg border-b-4 border-transparent hover:border-blue-500 transition-all duration-300 ease-in-out mb-5">
                        <img src="./images/skills/javascript-logo.png" alt="react" className='w-10 rounded mr-3' />
                        <div className="skill-meta">
                            <h2 className='text-2xl text-blue-500 font-semibold'>Javascript</h2>
                        </div>
                    </div>
                    <div className="single-skill-box flex items-center shadow-lg justify-center py-5 rounded-lg border-b-4 border-transparent hover:border-blue-500 transition-all duration-300 ease-in-out mb-5">
                        <img src="./images/skills/firebase.png" alt="react" className='w-10 rounded mr-3' />
                        <div className="skill-meta">
                            <h2 className='text-2xl text-blue-500 font-semibold'>Firebase Auth</h2>
                        </div>
                    </div>
                    <div className="single-skill-box flex items-center shadow-lg justify-center py-5 rounded-lg border-b-4 border-transparent hover:border-blue-500 transition-all duration-300 ease-in-out mb-5">
                        <img src="./images/skills/tailwind css.png" alt="react" className='w-10 rounded mr-3' />
                        <div className="skill-meta">
                            <h2 className='text-2xl text-blue-500 font-semibold'>Tailwind Css</h2>
                        </div>
                    </div>
                    <div className="single-skill-box flex items-center shadow-lg justify-center py-5 rounded-lg border-b-4 border-transparent hover:border-blue-500 transition-all duration-300 ease-in-out mb-5">
                        <img src="./images/skills/bootstrap.png" alt="react" className='w-10 rounded mr-3' />
                        <div className="skill-meta">
                            <h2 className='text-2xl text-blue-500 font-semibold'>Bootstrap</h2>
                        </div>
                    </div>
                    <div className="single-skill-box flex items-center shadow-lg justify-center py-5 rounded-lg border-b-4 border-transparent hover:border-blue-500 transition-all duration-300 ease-in-out mb-5">
                        <img src="./images/skills/node.png" alt="react" className='w-10 rounded mr-3' />
                        <div className="skill-meta">
                            <h2 className='text-2xl text-blue-500 font-semibold'>Node Js</h2>
                        </div>
                    </div>
                    <div className="single-skill-box flex items-center shadow-lg justify-center py-5 rounded-lg border-b-4 border-transparent hover:border-blue-500 transition-all duration-300 ease-in-out mb-5">
                        <img src="./images/skills/express.png" alt="react" className='w-10 rounded mr-3' />
                        <div className="skill-meta">
                            <h2 className='text-2xl text-blue-500 font-semibold'>Express Js</h2>
                        </div>
                    </div>
                    <div className="single-skill-box flex items-center shadow-lg justify-center py-5 rounded-lg border-b-4 border-transparent hover:border-blue-500 transition-all duration-300 ease-in-out mb-5">
                        <img src="./images/skills/mongodb.png" alt="react" className='w-10 rounded mr-3' />
                        <div className="skill-meta">
                            <h2 className='text-2xl text-blue-500 font-semibold'>MongoDB</h2>
                        </div>
                    </div>
                    <div className="single-skill-box flex items-center shadow-lg justify-center py-5 rounded-lg border-b-4 border-transparent hover:border-blue-500 transition-all duration-300 ease-in-out mb-5 px-2">
                        <img src="./images/skills/wordpress.jpg" alt="react" className='w-10 rounded mr-3' />
                        <div className="skill-meta">
                            <h2 className='text-2xl text-blue-500 font-semibold'>WP Theme Customization</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Skilled;