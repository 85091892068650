import React from 'react';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa'

const ContactInfo = () => {
    return (
        <div>
            <div className="container mx-auto pb-10">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <a href="mailto:monibbormon@gmail.com">
                        <div className="contact-box text-center p-5 shadow-lg mb-3 rounded-lg border-t-4 border-b-4 ease-in-out border-transparent hover:border-blue-500 transition-all duration-300">
                            <FaEnvelope className='mx-auto text-3xl text-blue-500 mb-3'></FaEnvelope>
                            <p className='font-semibold text-blue-500'>monibbormon@gmail.com</p>
                        </div>
                    </a>
                    <a href="tel:+880-1992442075">
                        <div className="contact-box text-center p-5 shadow-lg mb-3 rounded-lg border-t-4 border-b-4 ease-in-out border-transparent hover:border-blue-500 transition-all duration-300">
                            <FaPhoneAlt className='mx-auto text-3xl text-blue-500 mb-3'></FaPhoneAlt>
                            <p className='font-semibold text-blue-500'>+880-1992442075</p>
                        </div>
                    </a>
                    <div className="contact-box text-center p-5 shadow-lg mb-3 rounded-lg border-t-4 border-b-4 ease-in-out border-transparent hover:border-blue-500 transition-all duration-300">
                        <FaMapMarkerAlt className='mx-auto text-3xl text-blue-500 mb-3'></FaMapMarkerAlt>
                        <p className='font-semibold text-blue-500'>Gazipur,Dhaka,Bangladesh</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactInfo;