import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { FaCaretRight } from 'react-icons/fa'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProjectDetails = () => {
    const { title } = useParams()
    const [projects, setProjects] = useState([])
    useEffect(() => {
        fetch('/projects.json')
            .then(res => res.json())
            .then(data => {
                setProjects(data)
                console.log(data)
            })
    }, [])
    // slice slider
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    };
    return (
        <div>
            <div className="container mx-auto py-10 px-5 lg:px-0 pt-24">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {
                        projects?.filter(pd => pd?.projectName.toLowerCase() === title.toLowerCase())?.map(project => <div key={project.id}>
                            <div>
                                <Slider {...settings}>
                                    <div>
                                        <img src={project.imgThumb} alt="slide-img" />
                                    </div>
                                    <div>
                                        <img src={project.imgSlide2} alt="slide-img" />
                                    </div>
                                    <div>
                                        <img src={project.imgSlide3} alt="slide-img" />
                                    </div>
                                    <div>
                                        <img src={project.imgSlide4} alt="slide-img" />
                                    </div>
                                </Slider>
                            </div>
                            <h2 className='text-4xl mt-12 mb-5 capitalize'>Project Name: {project.projectName}</h2>
                            <p className='mb-5'>Category: <span className='font-semibold'>{project.category}</span></p>
                            <p>{project.description}</p>
                            <div className='mb-5'>
                                <h4 className='text-3xl mt-5'>Features of this project</h4>
                                <ul>
                                    <li className='ml-5'><span className='inline-block -mb-1'><FaCaretRight /></span>{project.overview1}</li>
                                    <li className='ml-5'><span className='inline-block -mb-1'><FaCaretRight /></span>{project.overview2}</li>
                                    <li className='ml-5'><span className='inline-block -mb-1'><FaCaretRight /></span>{project.overview3}</li>
                                </ul>
                            </div>
                            <div className="project-technology mt-5">
                                {
                                    project.tech1 && <button className='bg-blue-500 mb-1 text-white font-semibold px-2 rounded-md mr-1 text-sm py-1'>{project?.tech1}</button>
                                }
                                {
                                    project.tech2 && <button className='bg-blue-500 mb-1 text-white font-semibold px-2 rounded-md mr-1 text-sm py-1'>{project?.tech2}</button>
                                }
                                {
                                    project.tech3 && <button className='bg-blue-500 mb-1 text-white font-semibold px-2 rounded-md mr-1 text-sm py-1'>{project?.tech3}</button>
                                }
                                {
                                    project.tech4 && <button className='bg-blue-500 mb-1 text-white font-semibold px-2 rounded-md mr-1 text-sm py-1'>{project?.tech4}</button>
                                }
                                {
                                    project.tech5 && <button className='bg-blue-500 mb-1 text-white font-semibold px-2 rounded-md mr-1 text-sm py-1'>{project?.tech5}</button>
                                }
                                {
                                    project.tech6 && <button className='bg-blue-500 mb-1 text-white font-semibold px-2 rounded-md mr-1 text-sm py-1'>{project?.tech6}</button>
                                }
                                {
                                    project.tech7 && <button className='bg-blue-500 mb-1 text-white font-semibold px-2 rounded-md mr-1 text-sm py-1'>{project?.tech7}</button>
                                }
                                {
                                    project.tech8 && <button className='bg-blue-500 mb-1 text-white font-semibold px-2 rounded-md mr-1 text-sm py-1'>{project?.tech8}</button>
                                }
                            </div>
                            <div className="web-links">
                                <a href={project.liveLink} className='border-2 mr-3 border-blue-500 px-3 py-2 mt-4 rounded-full inline-block' target='_blank' rel="noreferrer">Live Link</a>
                                {project.clientCode && <a href={project.clientCode} className='border-2 mr-3 border-blue-500 px-3 py-2 mt-4 rounded-full inline-block' target='_blank' rel="noreferrer">Client Side Code</a>}
                                {project.serverCode && <a href={project.serverCode} className='border-2 mr-3 border-blue-500 px-3 py-2 mt-4 rounded-full inline-block' target='_blank' rel="noreferrer">Server Side Code</a>}
                            </div>
                        </div>)
                    }
                </div>
            </div>
        </div >
    );
};

export default ProjectDetails;