import React, { useState, useEffect } from 'react';
import './Projects.css'
import { FaLink, FaEye } from 'react-icons/fa'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import loader from '../../images/loader.svg';

/* const projectsData = [
    {
        id: 1,
        projectName: 'modern car house',
        description: 'This is a niche website and the product is car Anyone can buy cars and has admin functionality.',
        projectType: 'Full stack web application',
        category: 'Ecommerce',
        tech1: 'react js',
        tech2: 'Node js',
        tech3: 'express js',
        tech4: 'mongoDB',
        tech5: 'tailwind css',
        tech6: 'react-router',
        tech7: 'firebase authentication',
        tech8: 'heroku',
        overview1: 'Functional car buying website',
        overview2: 'book your favourite tour and view other order details on my order page',
        overview3: 'admin panel ',
        liveLink: 'https://modern-car-house.web.app/',
        clientCode: 'https://modern-car-house.web.app/',
        serverCode: 'https://modern-car-house.web.app/'

    },
    {
        id: 2,
        projectName: 'travel guide plus',
        description: 'This is a travel website and a travel agency.',
        projectType: 'Full stack web application',
        category: 'Travel Agency',
        tech1: 'react js',
        tech2: 'Node js',
        tech3: 'express js',
        tech4: 'mongoDB',
        tech5: 'tailwind css',
        tech6: 'react-router',
        tech7: 'firebase authentication',
        tech8: 'heroku',
        overview1: 'Functional Travel agency',
        overview2: 'book your favourite tour and view other order details on my order page',
        overview3: 'admin panel ',
        liveLink: 'https://travel-guide-plus.web.app/',
        clientCode: 'https://travel-guide-plus.web.app/',
        serverCode: 'https://travel-guide-plus.web.app/'

    },
    {
        id: 3,
        projectName: 'Responsive construction template',
        description: 'This is a niche website and the product is car Anyone can buy cars and has admin functionality.',
        projectType: 'Full stack web application',
        category: 'Travel Agency',
        tech1: 'react js',
        tech2: 'Node js',
        tech3: 'express js',
        tech4: 'mongoDB',
        tech5: 'tailwind css',
        tech6: 'react-router',
        tech7: 'firebase authentication',
        tech8: 'heroku',
        overview1: 'Functional Travel agency',
        overview2: 'book your favourite tour and view other order details on my order page',
        overview3: 'admin panel ',
        liveLink: 'https://travel-guide-plus.web.app/',
        clientCode: 'https://travel-guide-plus.web.app/',
        serverCode: 'https://travel-guide-plus.web.app/'

    },
] */
const Projects = () => {
    const [projects, setProjects] = useState([])

    useEffect(() => {
        fetch('/projects.json')
            .then(res => res.json())
            .then(data => setProjects(data))
    }, [])
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Monib Bormon | Portfolio Projects</title>
                <meta name="description" content="Monib Bormon is a MERN stack web developer who builds interactive websites for client." />
                <meta name='keywords' content='Monib Bormon, Web Developer, Mern Stack Developer, Front end developer' />
            </Helmet>

            <div className='bg-gray-50'>
                <div className='bg-blend-overlay bg-opacity-75 bg-black flex justify-center items-center project-title pt-14' style={{ backgroundImage: 'url(https://image.freepik.com/free-photo/web-design-concept-with-drawings_1134-77.jpg)', width: '100%', height: '250px', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center' }}>
                    <h2 className='lg:text-7xl text-5xl text-white text-center px-5'>Things I've Build</h2>
                </div>
                <div className="md:container md:mx-auto px-5 lg:px-0 py-10">
                    <div className="project-title1 mb-20">
                        <h2 className='text-center lg:text-5xl text-3xl pb-1'>Featured Projects</h2>
                        <span className='block w-32 h-1 bg-blue-500 mx-auto'></span>
                        <span className='block w-20 mt-1 h-1 bg-red-500 mx-auto'></span>
                    </div>
                    {
                        projects.length === 0 ? <img className='mx-auto' src={loader} alt="" /> :
                            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                                {
                                    projects.map(project => <div key={project.id} className="project-box rounded-lg shadow-lg mb-5">
                                        <div className="project-image-box">
                                            <img className='project-img rounded-t-lg' src={project.imgThumb} alt="project_image" />
                                        </div>
                                        <div className='p-5'>
                                            <div className="project-links flex justify-end mb-1">
                                                {project?.preview && <Link to={{ pathname: `${project?.preview}` }} target='_blank'>
                                                    <FaEye className='text-xl text-blue-600 mr-5'></FaEye>
                                                </Link>}
                                                <Link to={{ pathname: `${project.liveLink}` }} target='_blank'>
                                                    <FaLink className='text-xl text-blue-600'></FaLink>
                                                </Link>
                                            </div>
                                            <div className="project-text">
                                                <a href={project.liveLink} target='_blank' rel="noreferrer"><h2 className='text-2xl font-semibold text-blue-500 capitalize'>{project.projectName}</h2></a>
                                            </div>

                                            <div>
                                                <h4 className='text-gray-500 text-sm'>{project?.projectType}</h4>
                                            </div>

                                            <div className="project-technology mt-3">
                                                {
                                                    project?.tech?.map((t => <button key={t} className='bg-blue-500 mb-1 text-white font-semibold px-2 rounded-md mr-1 text-sm py-1'>{t}</button>))
                                                }
                                            </div>
                                            {/* <div className="link-buttons">
                                                <Link to={`/project-details/${project.projectName}`}>
                                                    <button className='border-2 border-blue-500 px-2 py-1 mt-5'>View Project Details</button>
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>)
                                }
                            </div>
                    }
                </div>
            </div>
        </>
    );
};

export default Projects;

